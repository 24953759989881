
import { defineComponent, ref } from 'vue'
export default defineComponent({
  name: 'Search',
  emits: ['searchList'],
  setup(props, { emit }) {
    const userName = ref('')

    const search = () => {
      emit('searchList', {
        userName: userName.value
      })
    }

    return {
      search,
      userName
    }
  }
})
