
import dayjs from 'dayjs'
import axios from 'axios'
import { ElMessage } from 'element-plus'
import { defineComponent, onMounted, ref } from 'vue'
import Search from './components/Search.vue'
export default defineComponent({
  name: 'Sign',
  components: { Search },
  setup() {
    const count = ref(0)
    const userName = ref('')
    const user_name = ref('')
    const remainCount = ref(0)
    const newPassword = ref('')
    const password = ref('')
    const checkStatus = ref(0)
    const tableData = ref([])
    const dialogVisible = ref(false)
    const editDialogVisible = ref(false)
    const seeDialogVisible = ref(false)
    const userDialogVisible = ref(false)
    const total = ref(0)
    const pageSize = 10
    const page = ref(1)
    const userId = ref(0)
    const handleClose = () => {
      dialogVisible.value = !dialogVisible.value
    }

    const userHandleClose = () => {
      userDialogVisible.value = !userDialogVisible.value
    }

    const fileList = [
      {
        name: 'food.jpeg',
        url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
      },
      {
        name: 'food2.jpeg',
        url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
      }
    ]

    const onChangePage = (val: number) => {
      page.value = val
      getList('')
    }

    const getList = (val: string) => {
      const reqData: { user_name?: string; size: number; page: number } = {
        size: pageSize,
        page: page.value
      }
      if (val) {
        reqData.user_name = val
      }
      axios.get('/-/admin_sms/user/list', { params: reqData }).then(res => {
        const data = res.data.msg
        tableData.value = data.users
        total.value = data.total
      })
    }
    const add = (id: number) => {
      dialogVisible.value = true
      userId.value = id
    }

    const addNum = () => {
      const reqData = {
        user_id: userId.value,
        remaining_quota: Number(count.value)
      }

      axios
        .post('/-/admin_sms/user/add_remaining_quota', reqData)
        .then(() => {
          dialogVisible.value = false
          count.value = 0
          getList('')
          ElMessage.success('新增条数成功')
        })
        .catch(e => {
          console.warn(e)
        })
    }

    const addUser = () => {
      const reqData = {
        user_name: userName.value,
        password: password.value,
        remaining_quota: Number(remainCount.value)
      }

      axios
        .post('/-/admin_sms/user/new', reqData)
        .then(() => {
          ElMessage.success('创建成功')
          userDialogVisible.value = false
          getList('')
        })
        .catch(e => {
          console.warn(e)
        })
    }

    const formatTime = (val: string) => {
      return dayjs(val).format('YYYY-MM-DD HH:mm:ss')
    }

    const refreshList = (searchPhone: { userName: string }) => {
      console.log(searchPhone, '----searchPhone---')
      // user_name.value = searchPhone.userName
      getList(searchPhone.userName)
    }

    onMounted(() => {
      getList('')
    })

    return {
      add,
      page,
      total,
      count,
      addNum,
      addUser,
      getList,
      userName,
      pageSize,
      user_name,
      password,
      fileList,
      tableData,
      formatTime,
      remainCount,
      newPassword,
      checkStatus,
      handleClose,
      refreshList,
      onChangePage,
      dialogVisible,
      userHandleClose,
      seeDialogVisible,
      userDialogVisible,
      editDialogVisible
    }
  }
})
